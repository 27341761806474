<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <b-row class="justify-content-center">
        <b-col md="6">
          <div class="clearfix">
            <h1 class="float-left display-3 mr-4">404</h1>
            <h4 class="pt-3">{{$t('notFoundTitle')}}</h4>
            <p class="text-muted"></p>
          </div>
          <b-input-group>
            <b-input-group-append>
              <b-button variant="primary" @click="goToMainPage">{{$t('home')}}</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "Page404",
  methods: {
    goToMainPage() {
       this.$router.push({ path: "/dashboard" });
    }
  }
};
</script>
